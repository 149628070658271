//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EventViewExpired',
  methods: {
    onView: function onView() {
      var short_key = this.$route.query.short_key;

      if (!short_key) {
        this.$router.push('/alarm');
      } else {
        this.$router.push("/service/alarm-view?short_key=".concat(short_key));
      }
    }
  }
};